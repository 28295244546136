<template>
    <div class="real-data">
        <div class="body">
            <p class="col">
                <span class="description">监控点名称</span>
                <span class="name">{{detail.name}}</span>
            </p>
            <p class="col">
                <span class="description">采集时间</span>
                <span class="name">{{detail.CreatedAt}}</span>
            </p>
			<div class="list">
				<div class="list-box">
					<p class="list-header">
						<span class="cell">数据类型</span>
						<span class="cell">数据值</span>
					</p>
					<div class="list-body">
						<p class="list-one" v-for="item in detail.data">
							<span class="cell">{{item['key-text']}}</span>
							<span class="cell">{{item.value}}</span>
						</p>
					</div>
				</div>
			</div>
        </div>
        <div class="footer" v-show="!isPc">
			<span class="btn" @click="back">返回</span>
		</div>
		<p class="van-safe-area-bottom"></p>
    </div>
</template>

<script>
    import {defineComponent, onMounted, toRefs, reactive} from "vue"
	import {useRouter,useRoute} from "vue-router"
	import api from "@/api/methods"
	import myPublic from "@/utils/public"
	import * as dd from 'dingtalk-jsapi';
    export default defineComponent({
        name: "real-data",
        setup(props, {emit}) {
        	const router = useRouter();
        	const route = useRoute();
            const state = reactive({
				detail: {
					name:'',
					createdAt:'',
					data:[]
				},
				isPc:dd.pc
            });

            function getDetail() {
				api.process.processDataDetail({id:Number(route.query.data_id)}).then((res)=>{
					if(res.code==0){
						if(res.data){
							state.detail.name = res.data.device_info.name;
							state.detail.CreatedAt = myPublic.dateFormat.format(res.data.CreatedAt,'YYYY-MM-DD HH:mm');
							state.detail.data = myPublic.isJsonString(res.data.data)?JSON.parse(res.data.data):[];
						}
					}
				})
            }
			function back(){
            	if(state.isPc){

				}else{
					router.go(-1);
				}
			}
            onMounted(() => {
                getDetail();
            });
            return {
                ...toRefs(state),
				back
            }
        }
    })
</script>

<style scoped lang="less">
    .real-data {
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .body {
            flex: 1;
			display: flex;
			flex-direction: column;
			border-top: .03rem solid #eef2f5;
			overflow: hidden;
			.col{
				flex-shrink: 0;
				display: flex;
				height: .6rem;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #e0e5eb;
				margin:0 .2rem;
				.description{
					flex-shrink: 0;
					color: #666;
				}
				.name{
					flex: 1;
					text-align: right;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					margin-left: .1rem;
				}
			}
			.col:nth-child(2){
				border-bottom: none;
			}
			.list{
				flex: 1;
				border-top: .03rem solid #eef2f5;
				padding:.2rem;
				overflow: hidden;
				.list-box{
					height: 100%;
					display: flex;
					flex-direction: column;
					overflow: hidden;
					border-left:1px solid #eef2f5;
					border-top:1px solid #eef2f5;
					.list-header{
						flex-shrink: 0;
						display: flex;
						height: .5rem;
						border-top-left-radius: .04rem;
						border-top-right-radius: .04rem;
						background-color: #2aa0f7;
						color: #fff;
					}
					.list-body{
						flex: 1;
						overflow-y: auto;
						.list-one{
							flex-shrink: 0;
							display: flex;
							color: #353944;
							height: .5rem;
							&:nth-child(even){
								background-color: #eef2f5;
							}
						}
					}
				}
				.cell{
					flex: 1;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-right:1px solid #eef2f5;
					border-bottom:1px solid #eef2f5;
				}
			}
        }
        .footer {
            flex-shrink: 0;
			padding:.05rem .15rem;
			border-top: 1px solid #efefef;
			.btn{
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: .4rem;
				border-radius: .04rem;
				background-color: #2aa0f7;
				color: #fff;
				font-size: .12rem;
				cursor: pointer;
				&:active{
					opacity: .8;
				}
			}
        }
    }
</style>
